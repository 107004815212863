
const lifeExpectancyChart = (element) => {


  const createChart = (data) => {
    const highest = data.highest.score;

    const getSegementHeight = (value) => {
      return Math.round((value / highest) * 100);
    };

    const segments = {
      lowest: getSegementHeight(data.lowest.score),
      result: getSegementHeight(data.result.score),
      closest: getSegementHeight(data.closest.score),
      highest: 100,
    };

    return `
      <div class="le-chart">
        <div class="le-chart__wrapper">
          ${segments.lowest > 0 ?
            `<div class="le-chart__segment lowest" style="height: ${segments.lowest}%;">
              <span>Lowest</span>
              <div class="le-chart__score">
                <span>${data.lowest.score}</span> years
              </div>
            </div>` : ''}
          ${data.result.score <= data.closest.score ? (`
            ${segments.result > 0 ?
              `<div class="le-chart__segment current ${data.result.colour}" style="height: ${segments.result}%;">
                <span>&nbsp;</span>
                <div class="le-chart__score">
                  <span>${data.result.score}</span> years
                </div>
              </div>` : ''}
            ${segments.closest > 0 ?
              `<div class="le-chart__segment closest" style="height: ${segments.closest}%;">
                <span>&nbsp;</span>
                <div class="le-chart__score">
                  <span>${data.closest.score}</span> years
                </div>
              </div>` : ''}
          `) : (`
            ${segments.closest > 0 ?
              `<div class="le-chart__segment closest" style="height: ${segments.closest}%;">
                <span>&nbsp;</span>
                <div class="le-chart__score">
                  <span>${data.closest.score}</span> years
                </div>
              </div>` : ''}
            ${segments.result > 0 ?
              `<div class="le-chart__segment current ${data.result.colour}" style="height: ${segments.result}%;">
                <span>&nbsp;</span>
                <div class="le-chart__score">
                  <span>${data.result.score}</span> years
                </div>
              </div>` : ''}
          `)}
          ${segments.highest > 0 ?
            `<div class="le-chart__segment highest" style="height: ${segments.highest}%;">
              <span>Highest</span>
              <div class="le-chart__score">
                <span>${data.highest.score}</span> years
              </div>
            </div>` : ''}
        </div>
        <div class="le-chart__keys">
          ${segments.lowest > 0 ? `<div class="le-chart__key">${data.lowest.name}</div>` : ''}
          ${data.result.score <= data.closest.score ? (`
            ${segments.result > 0 ? '<div class="le-chart__key">Your life expectancy</div>' : ''}
            ${segments.closest > 0 ? `<div class="le-chart__key">${data.closest.name}</div>` : ''}
          `) : (`
            ${segments.closest > 0 ? `<div class="le-chart__key">${data.closest.name}</div>` : ''}
            ${segments.result > 0 ? '<div class="le-chart__key">Your life expectancy</div>' : ''}
          `)}
          ${segments.highest > 0 ? `<div class="le-chart__key">${data.highest.name}</div>` : ''}
        </div>
      </div>
    `;
  };


  element.innerHTML = createChart(
    JSON.parse(element.dataset.leData)
  );
};


export default lifeExpectancyChart;

