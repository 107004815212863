

const initTipsDialog = () => {
  const tipsDialog = document.querySelector('.js-tips-modal');

  if(!tipsDialog) return;

  const closeModal = document.querySelector('.js-close-tips-modal');

  const prevTip = document.querySelector('.js-tips-prev');
  const nextTip = document.querySelector('.js-tips-next');

  const loadModalForTip = (index) => {
    const template = document.querySelector(`#tips-item-${ index }`);
    tipsDialog.querySelector('.js-tips-modal-content').innerHTML = template.innerHTML;
    tipsDialog.setAttribute('data-current', index);
    tipsDialog.showModal();
  };
  
  const onClickTip = (clickedTip, ev) => {
    ev.preventDefault();

    loadModalForTip(clickedTip.dataset.index);
  };

  document.addEventListener('click', e => {
    let currentNode = e.target;
    if(e.target && e.target.nodeName && e.target.nodeName.toLowerCase() == 'div') return false; // bail on traversing the document if we hit a div element
    while(true) { // search parents to find out if we've clicked a share link
      if(currentNode.matches('.js-tip-item')) {
        return onClickTip(currentNode, e);
      } else if(currentNode != e.currentTarget && currentNode != document.body && (currentNode.nodeName && currentNode.nodeName.toLowerCase() != 'div')) {
        currentNode = currentNode.parentNode;
      } else {
        return false;
      }
    }
  });

  prevTip.addEventListener('click', (ev) => {
    const tipsMax = Math.max(...Array.from(document.querySelectorAll('.js-tip-item')).map(tip => parseInt(tip.dataset.index)));
    const current = parseInt(tipsDialog.dataset.current);
    const previous = current - 1 < 0 ? tipsMax : current - 1;
    loadModalForTip(previous);
  });

  nextTip.addEventListener('click', (ev) => {
    const tipsMax = Math.max(...Array.from(document.querySelectorAll('.js-tip-item')).map(tip => parseInt(tip.dataset.index)));
    const current = parseInt(tipsDialog.dataset.current);
    const next = current + 1 > tipsMax ? 0 : current + 1;
    loadModalForTip(next);
  });

  closeModal.addEventListener('click', (ev) => {
    ev.preventDefault();
    tipsDialog.close();
  });
};

const initShareDialog = () => {
  const shareDialog = document.querySelector('.js-share-modal');

  if(!shareDialog) return;

  const shareButton = document.querySelector('.js-share-results-button');
  const closeModal = document.querySelector('.js-close-share-modal');

  shareButton.addEventListener('click', (ev) => {
    ev.preventDefault();
    shareDialog.showModal();
  });

  closeModal.addEventListener('click', (ev) => {
    ev.preventDefault();
    shareDialog.close();
  });
};

const modals = () => {
  initTipsDialog();
  initShareDialog();

  // const dialogs = document.querySelectorAll('dialog:not(.ready)');
  const dialogs = document.querySelectorAll('dialog');

  dialogs.forEach(_dialog => {
    _dialog.addEventListener('mousedown', event => {
      if (event.target === event.currentTarget) {
        event.currentTarget.close()
      }
    });
  });

  // dialogs.forEach(_dialog => _dialog.classList.add('ready'));
};

export default modals;
