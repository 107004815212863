
const channels = {
  facebook : (url, message) => `http://www.facebook.com/sharer/sharer.php?u=${url}${!!message ? `&quote=${message}` : ""}`,
  twitter  : (url, message) => `http://twitter.com/share?url=${url}${!!message ? `&text=${message}` : "" }`,
  linkedin : (url, message) => `https://www.linkedin.com/shareArticle?url=${url}${!!message ? `&summary=${message}` : ""}`,
  whatsapp : (url, message) => `https://wa.me/?text=${message}%20${url}`,
  email    : (url, message) => `mailto:?subject=Personal Happy Planet Index calculator&body=${message}%20${url}`,
  messenger: (url, message) => `fb-messenger://share?link=${url}`,
};

const getChannelUrl = (channel, url, message) => {
  let _url      = encodeURIComponent(url),
      _message  = encodeURIComponent(message.trim());
  return channels[channel](_url, _message);
};

const da = (el, attribute, def = "") => {
  if(el.hasAttribute(`data-social-${attribute}`)) return el.getAttribute(`data-social-${attribute}`);
  return def;
};

const onClickShareLink = (_target, _event) => {
  let channel = da(_target, 'channel', null),
      section = da(_target, 'section', ""),
      message = da(_target, 'message', "");

  let shareUrl = da(_target, 'url', `${window.location.href}`),
      targetUrl = getChannelUrl(channel, shareUrl, message);

  if(channel == "whatsapp" || channel == "email" || channel == "messenger") return _target.setAttribute('href', targetUrl);
  else _event.preventDefault();

  return window.open(targetUrl, "popup", "width=500,height=400");
};


const initShareLinks = () => {
  document.addEventListener('click', e => {
    let currentNode = e.target;
    if(e.target && e.target.nodeName && e.target.nodeName.toLowerCase() == 'div') return false; // bail on traversing the document if we hit a div element
    while(true) { // search parents to find out if we've clicked a share link
      if(currentNode.matches('[data-social]')) {
        return onClickShareLink(currentNode, e);
      } else if(currentNode != e.currentTarget && currentNode != document.body && (currentNode.nodeName && currentNode.nodeName.toLowerCase() != 'div')) {
        currentNode = currentNode.parentNode;
      } else {
        return false;
      }
    }
  });
};

export default initShareLinks;
