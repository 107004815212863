

const carbonFootprintChart = (element) => {

  const createChart = (data) => {
    const totalCF = data.total;

    const getSegmentPercentage = (value) => {
      if(value <= 0) return 0;

      return Math.round((value / totalCF) * 100);
    };

    const segments = {
      travel: getSegmentPercentage(data.travel_footprint),
      food: getSegmentPercentage(data.food_footprint),
      housing: getSegmentPercentage(data.housing_footprint),
      other: getSegmentPercentage(data.other_footprint),
    };

    return `
      <div class="cf-chart">
        <div class="cf-chart__wrapper">
          ${segments.travel > 0 ?
            `<div class="cf-chart__segment travel" style="width: ${segments.travel}%;"><span>${segments.travel}%</span></div>` : ''}
          ${segments.food > 0 ?
            `<div class="cf-chart__segment food" style="width: ${segments.food}%;"><span>${segments.food}%</span></div>` : ''}
          ${segments.housing > 0 ?
            `<div class="cf-chart__segment housing" style="width: ${segments.housing}%;"><span>${segments.housing}%</span></div>` : ''}
          ${segments.other > 0 ?
            `<div class="cf-chart__segment other" style="width: ${segments.other}%;"><span>${segments.other}%</span></div>` : ''}
        </div>
        <div class="cf-chart__keys">
          ${segments.travel > 0 ? '<div class="cf-chart__key travel">Transport</div>' : ''}
          ${segments.food > 0 ? '<div class="cf-chart__key food">Food</div>' : ''}
          ${segments.housing > 0 ? '<div class="cf-chart__key housing">Housing</div>' : ''}
          ${segments.other > 0 ? '<div class="cf-chart__key other">Other</div>' : ''}
        </div>
      </div>
    `;
  };

  element.innerHTML = createChart(
    JSON.parse(element.dataset.cfData)
  );
};

export default carbonFootprintChart;