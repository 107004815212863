
const wellbeingChart = (element) => {

  const createChart = (data) => {
    const segments = [
      {
        label: 'Take notice',
        percent: data.little_things,
      }, {
        label: 'Keep learning',
        percent: data.learning_frequency,
      }, {
        label: 'Give',
        percent: data.charity_frequency,
      }, {
        label: 'Connect',
        percent: data.socialise_frequency,
      }, {
        label: 'Be active',
        percent: data.exercise_frequency,
      },
    ];

    return `
      <div class="wb-chart">
        <div class="wb-chart__wrapper">
          ${segments.map(segment => (
           `<div class="wb-chart__segment">
              <div class="wb-chart__fill" style="height: ${segment.percent}%;"></div>
            </div>`
          )).join('')}
        </div>
        <div class="wb-chart__keys">
          ${segments.map(segment => (
            `<div class="wb-chart__key">${segment.label}</div>`
          )).join('')}
        </div>
      </div>
    `;
  };  

  element.innerHTML = createChart(
    JSON.parse(element.dataset.wbData)
  );
};

export default wellbeingChart;