import country from "world-map-country-shapes";
import getCountryISO2 from "country-iso-3-to-2";

const CountryShapes = (function() {

  const init = () => {
    let container = document.querySelector('.js-country-shape');

    if(!container) return;

    let code = container.dataset.country;

    let svg = buildSVGFor(getCountryISO2(code));
    
    if(!!svg) {
      container.appendChild(svg);
      scaleToFitViewBox(svg);
    }
  }

  const buildSVGFor = (code) => {
    let countryPath = country.filter(c => c.id == code)[0];

    if(!countryPath) return "";


    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg"),
        path = document.createElementNS("http://www.w3.org/2000/svg", "path");

        svg.setAttributeNS(null, 'height', "400");
        svg.setAttributeNS(null, 'width', "400");
        svg.setAttributeNS(null, 'viewBox', "0 0 400 400");

        path.setAttributeNS(null, 'fill', "#27272733");
        path.setAttributeNS(null, 'd', countryPath.shape);

    svg.appendChild(path);

    return svg;
  };

  const scaleToFitViewBox = (svg) => {
    const { xMin, xMax, yMin, yMax } = [...svg.children].reduce((acc, el) => {
      const { x, y, width, height } = el.getBBox();
      if (!acc.xMin || x < acc.xMin) acc.xMin = x;
      if (!acc.xMax || x + width > acc.xMax) acc.xMax = x + width;
      if (!acc.yMin || y < acc.yMin) acc.yMin = y;
      if (!acc.yMax || y + height > acc.yMax) acc.yMax = y + height;
      return acc;
    }, {});

    const viewbox = `${xMin.toFixed(2)} ${yMin.toFixed(2)} ${(xMax - xMin).toFixed(2)} ${(yMax - yMin).toFixed(2)}`;
    svg.setAttribute('viewBox', viewbox);

    return svg;
  };

  return {
    init: init,
  }

}());

export default CountryShapes;
